import AlexandruIliescu from '../assets/images/homePage/testimonials/alexandru_iliescu.jpg';
import IoanAdrianBuda from '../assets/images/homePage/testimonials/ioan_adrian_buda.jpg';
import GeaninaMiron from '../assets/images/homePage/testimonials/geanina_miron.jpg';
import VictorNimirceag from '../assets/images/homePage/testimonials/victor_nimirceag.jpg';
import DariusTofana from '../assets/images/homePage/testimonials/darius_tofana.jpg';
import DanielDulf from '../assets/images/homePage/testimonials/daniel-dulf.jpg';

export const testimonials = [
  {
    author: 'Alexandru Iliescu',
    position: 'home.testimonialsSection.alexIliescu.position',
    imageSrc: AlexandruIliescu,
    quote: 'home.testimonialsSection.alexIliescu.text',
  },
  {
    author: 'Ioan Adrian Buda',
    position: 'home.testimonialsSection.ioanAdrianBuda.position',
    imageSrc: IoanAdrianBuda,
    quote: 'home.testimonialsSection.ioanAdrianBuda.text',
  },
  {
    author: 'Geanina Miron',
    position: 'home.testimonialsSection.geaninaMiron.position',
    imageSrc: GeaninaMiron,
    quote: 'home.testimonialsSection.geaninaMiron.text',
  },
  {
    author: 'Victor Nimirceag',
    position: 'home.testimonialsSection.victorNimirceag.position',
    imageSrc: VictorNimirceag,
    quote: 'home.testimonialsSection.victorNimirceag.text',
  },
  {
    author: 'Darius Tofana',
    position: 'home.testimonialsSection.dariusTofana.position',
    imageSrc: DariusTofana,
    quote: 'home.testimonialsSection.dariusTofana.text',
  },
  {
    author: 'Daniel Dulf',
    position: 'home.testimonialsSection.danielDulf.position',
    imageSrc: DanielDulf,
    quote: 'home.testimonialsSection.danielDulf.text',
  },
];
